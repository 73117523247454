import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Connects to data-controller="category"
export default class extends Controller {
  static targets = [ "lastUpdate", "alertsContainer", "emptyCategoryModal" ]
  alertsContainerObserver = null;
  socketChannel = null;
  feedsData = {};

  connect() {
    this.setLastUpdateText()
    this.initObserver()
    if(!this.isCategoryParsed()){
      this.socketChannel = this.initChannelSubscription()
    }

    if(this.isCategoryParsed() && this.isCategoryEmpty()){
      this.emptyCategoryModalTarget.showModal()
    }
  }

  disconnect() {
    if (this.alertsContainerObserver) {
      this.alertsContainerObserver.disconnect();
    }

    if(this.socketChannel) {
      this.socketChannel.unsubscribe()
    }
  }

  setLastUpdateText() {
    const latestAlert = this.alertsContainerTarget.querySelector('a');
    if (latestAlert) {
      const timestamp = new Date(latestAlert.dataset.alertTimestamp);
      const day = timestamp.getDate().toString().padStart(2, '0');
      const month = timestamp.toLocaleString('en-GB', {month: 'short'});
      const year = timestamp.getFullYear();
      const hours = timestamp.getHours().toString().padStart(2, '0');
      const minutes = timestamp.getMinutes().toString().padStart(2, '0');

      const formattedDate = `${day} ${month} ${year} at ${hours}:${minutes}`;
      this.lastUpdateTarget.textContent = `Last updated ${formattedDate}`
    }
  }

  initObserver() {
    const observerOptions = {
      childList: true,
    };

    this.alertsContainerObserver = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.setLastUpdateText();
        }
      });
    });
    this.alertsContainerObserver.observe(this.alertsContainerTarget, observerOptions);
  }

  isCategoryParsed() {
    return this.element.dataset.categoryParsed === "true";
  }

  initChannelSubscription() {
    return consumer.subscriptions.create({ channel: "CategoryChannel", id: this.element.dataset.categoryId }, {
      received: (feed)=> {
        this.feedsData[feed.id] = feed.alerts_count;

        if(this.areAllFeedsParsed() && this.areFeedsEmpty()){
          this.emptyCategoryModalTarget.showModal()
        }
      }
    });
  }

  areAllFeedsParsed() {
    let currentFeedsCount = Object.keys(this.feedsData).length;
    let expectedFeedsCount = parseInt(this.element.dataset.feedsCount, 10);

    return currentFeedsCount === expectedFeedsCount;
  }

  areFeedsEmpty(){
    return Object.values(this.feedsData).every(value => value === 0);
  }

  isCategoryEmpty() {
    return this.element.dataset.categoryHasAlerts === "false";
  }
}
