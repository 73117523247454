import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {
  connect() {
  }

  onClick(event){
    if(this.element.dataset.alertUnsafe === "true") {
      event.preventDefault();

      const alertId = event.currentTarget.dataset.alertId;
      const dialog = document.getElementById(`alert_unsafe_modal_${alertId}`);
      dialog.showModal();
   } else if(this.element.dataset.alertPartiallyProcessed === "true") {
      event.preventDefault();

      const alertId = event.currentTarget.dataset.alertId;
      const dialog = document.getElementById(`alert_partially_analyzed_modal_${alertId}`);
      dialog.showModal();
    }
  }
}
