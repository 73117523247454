import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-header"
export default class extends Controller {
  static targets = ["menuIcon", "drawerStateToggler"];
  connect() {
    window.addEventListener("resize", this.handleResize.bind(this));

    this.closedMenuIcon = this.menuIconTarget.children[0];
    this.intermediaryMenuIcon = this.menuIconTarget.children[1];
    this.openMenuIcon = this.menuIconTarget.children[2];
  }

  handleResize() {
    if(this.isAboveMobileResolution() && this.isDrawerActive()){
      this.disableDrawer();
    }
  }

  isAboveMobileResolution() {
    const tableViewport = 768;

    return window.innerWidth >= tableViewport;
  }

  isDrawerActive() {
    return this.drawerStateTogglerTarget.checked === true;
  }

  disableDrawer() {
    this.drawerStateTogglerTarget.checked = false;

    // We need to dispatch an event in order to trigger the input change action
    const inputChangeEvent = new Event('change', { bubbles: true, cancelable: true });
    this.drawerStateTogglerTarget.dispatchEvent(inputChangeEvent);

  }

  toggleIconAnimation(event) {
    const drawerIsActive = event.target.checked;

    if (drawerIsActive) {
      this.activeDrawerAnimation();
    } else {
      this.inactiveDrawerAnimation();
    }
  }

  activeDrawerAnimation() {
    this.closedMenuIcon.classList.toggle("hidden");
    this.intermediaryMenuIcon.classList.toggle("hidden");

    setTimeout(() => {
      this.intermediaryMenuIcon.classList.toggle("hidden");
      this.openMenuIcon.classList.toggle("hidden");
    }, 100);
  }

  inactiveDrawerAnimation() {
      console.log("inactive drawee.")
      this.openMenuIcon.classList.toggle("hidden");
      this.intermediaryMenuIcon.classList.toggle("hidden");

      setTimeout(() => {
        this.intermediaryMenuIcon.classList.toggle("hidden");
        this.closedMenuIcon.classList.toggle("hidden");
      }, 100);
    }
}
