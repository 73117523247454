import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="drawer"
export default class extends Controller {
    static targets = ["arrow", "menu", "aside", "betaLabelContainer"];
    connect() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    toggleDrawerState() {
        this.arrowTarget.classList.toggle("rotate-180");
        this.menuTarget.classList.toggle("drawer-menu-extended");
        this.menuTarget.classList.toggle("drawer-menu-collapsed");
        this.updateBetaLabelPosition();

        const menuItems = this.menuTarget.querySelectorAll('li span')
        menuItems.forEach(function(item) {
            item.classList.toggle('opacity-0');
        })

        const drawerState = this.isDrawerCollapsed() ? "collapsed" : "extended";
        document.cookie = `drawer_state=${drawerState}; path=/; SameSite=Lax`;
    }

    updateBetaLabelPosition() {
        // Value taken from the CSS transition duration property
        const menuTransitionDuration = 300;
        const positionUpdatePoint = menuTransitionDuration / 3;

        setTimeout(() => {
            this.betaLabelContainerTarget.classList.toggle("flex-row");
            this.betaLabelContainerTarget.classList.toggle("flex-col");
        }, positionUpdatePoint);
    }

    handleResize() {
        const mediumScreenWidth = 768;
        if (window.innerWidth < mediumScreenWidth) {
            this.enableModalDrawerButton();

            if(this.isDrawerCollapsed()) {
                this.toggleDrawerState();
            }
        } else {
            this.disableModalDrawerButton();
        }
    }

    isDrawerCollapsed() {
        return this.menuTarget.classList.contains('drawer-menu-collapsed')
    }

    enableModalDrawerButton() {
        this.asideTarget.classList.add("drawer-side");
    }
    
    disableModalDrawerButton() {
        this.asideTarget.classList.remove("drawer-side");
    }
}
