import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
    static targets = ["dialog"]
    connect() {
        this.dialogTarget.showModal()
    }

    closeModal() {
        this.dialogTarget.remove()
    }
}
