import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="actionable-modal"
export default class extends Controller {
  static targets = ["dialog"]
  connect() {
  }

  closeModal() {
    this.dialogTarget.close()
  }
}
