import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="add-totp"
export default class extends Controller {
    toggle(e) {
        e.preventDefault();

        if (!e.target.checked) {
            this.submitCancel()
            return;
        }

        this.submitNew();
    }

    submitCancel() {
        this.element.action = this.element.dataset.cancelPath;
        this.element.requestSubmit();
    }

    submitNew() {
        this.element.action = this.element.dataset.newPath;
        this.element.requestSubmit();
    }
}
